import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import Sidebar from '../components/Sidebar'
import replaceAll from "../utils/replaceAll"
import IconArrow from "../components/icons/IconArrow";
import SidebarWidget from "../components/SidebarWidget"
import CandidateProfile from "../components/CandidateProfile"
import SignUpSection from "../components/SignUpSection"

// SVG Icon used in the article links
const linkIcon = () => (
  `<svg
    width="12" 
    height="12" 
    viewBox="0 0 12 12" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0v12h12V9h-1.5v1.5h-9v-9H3V0H0zm6 0l2.25 2.25L4.5 6 6 7.5l3.75-3.75L12 6V0H6z" fill="#E7513D"/>
  </svg>`
)

const replaceHeading = (article, heading) => {
  let newArticle = '';
  newArticle = article.replace('<h2>', `<h2 id="${heading.value}">`);
  return newArticle;
}

export default function ward({ data }) {

  // = = = = = = = = = = = = =
  // Forestry Data
  const aboutData = data.markdownRemark.frontmatter;
  const headings = data.markdownRemark.headings;
  let article = data.markdownRemark.html;
  const showWidgets = data.markdownRemark.frontmatter.would_you_like_to_show_widgets_bellow_the_main_article;
  const widgets = data.markdownRemark.frontmatter.bellow_article_widgets;

  // = = = = = = = = = = = = =
  // Utils

  // Add id to each Heading with title as a value
  headings.forEach( heading => {
    article = replaceHeading(article, heading);
  });

  // Add icon to each link of the article
  const replacedArticle = replaceAll(article, '</a>', `${linkIcon()}</a>`);

  return(
    <>
      <Seo title = { aboutData.page_title } />

      <div className="hero--ward">
        <div className="container">
          <div className="row">

            <div className="col-md-6 col-left">
              <div className="col-md-10">
                <h1>Farringdon Within</h1>
                <p>The ward of Farringdon Within elects XX Common Councillors</p>
                <button>Meet your candidates</button>
              </div>

              <StaticImage 
                className="hero--ward__picture"
                src="../../static/media/about-hero.jpg" 
                alt=""
                placeholder="blurred"
              />
            </div>

            <div className="col-md-6 col-right">
              <div className="bg-margin"></div>

              <h2>How can I vote?</h2>

              <div className="ward-info">
                <h3> <IconArrow/> In person on 24th March</h3>
                <p className="ward-info__place">Farringdon School</p>
                <div className="ward-info__content ward-info__address">
                  <div className="address">
                    <p>24 Holborn Viaduct, Chancery Ln London EC1A 2BN</p>
                    <p>Polls open: 8am to 8pm</p>
                  </div>
                  <button>Directions</button>
                </div>
              </div>

              <div className="ward-info">
                <h3> <IconArrow/> By post</h3>
                <div className="ward-info__content">
                  <p>If you would prefer to vote by post, your application must be signed by hand and received by 5pm on 9th March.</p>
                  <button>Apply now</button>
                </div>
              </div>

              <div className="ward-info">
                <h3> <IconArrow/> Appoint a proxy voter</h3>
                <div className="ward-info__content">
                  <p>If you are unable to get to the polling station, you can appoint someone else to vote on your behalf.</p>
                  <button>More info</button>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row">

            <div className="col-md-3">
              <Sidebar 
                headings = { data.markdownRemark.headings } 
                links = { aboutData.sidebar_links }>
                  
                  <SidebarWidget 
                    headline="Apply for a postal vote"
                    linkLabel="Must be received by 5pm on 9th March"
                    link="/"
                  >
                    <StaticImage 
                      className="sidebar__widget__image" 
                      src="../images/sidebar-widget.jpg" 
                      alt=""
                    />
                  </SidebarWidget>

                  <SidebarWidget 
                    headline="Stand for the City"
                    linkLabel="Become a Candidate"
                    link="/become-a-councillor"
                  >
                    <StaticImage 
                      className="sidebar__widget__image" 
                      src="../../static/media/sidebar-widget-2.jpg" 
                      alt=""
                    /> 
                  </SidebarWidget>

              </Sidebar>
            </div>

            <div className="col-md-9">

              <div className="page__introduction">
                <h2>Introduction</h2>
                <p>The City of London Wards find their origins in the Middle Ages. At the time, small areas within a city were able to act as self-governing communities with their own assemblies (wardmote) and systems of public order (elected beadles). Today there are 25 Wards in the City and although their boundaries have changed throughout the centuries, they still maintain their geographic and administrative entities together with their electoral and political duties.</p>
              </div>

              <div className="page__candidates">
                <h2>Your Candidates for Farringdon Within</h2>
                <h3>Candidates for common councillor</h3>
                <span className="headline-underline"/>

                <div className="candidates">
                  <div className="col-md-6">
                      <CandidateProfile
                        name="Guillermo Soler"
                        party="conservative"
                      />
                  </div>

                  <div className="col-md-6">
                      <CandidateProfile
                        name="Pedro Sanchez"
                        party="labour"
                      />
                  </div>

                  <div className="col-md-6">
                      <CandidateProfile
                        name="Guillermo Soler"
                        party="independent"
                      />
                  </div>

                  <div className="col-md-6">
                      <CandidateProfile
                        name="Pedro Sanchez"
                        party="labour"
                      />
                  </div>

                  <div className="col-md-6">
                      <CandidateProfile
                        name="Pedro Sanchez"
                        party="labour"
                      />
                  </div>
                </div>

              </div>

              <div className="page_candidates-meeting">
                <h2>Meet your Candidates</h2>
                <h4>23rd March: Farringdon Within wardmote</h4>
                <p>Venue: XXXXX</p>
                <p>Time: XXXXX</p>
                <p>Wardmotes are a chance to hear from the candidates and raise issues of concern with them. These meetings have been held since Saxon times and are a unique feature of City of London elections.</p>

              </div>
              
            </div>

          </div>
        </div>
      </div>

      <SignUpSection />
    </>
  )
}

// = = = = = = = = = = = = =
// GraphQL
export const query = graphql`
  query WardQuery {
    markdownRemark(frontmatter: {page_title: {eq: "About"}}) {
      frontmatter {
        page_title
        hero_headline
        hero_intro
        sidebar_links {
          link_label
          link_url
        }
        would_you_like_to_show_widgets_bellow_the_main_article
        bellow_article_widgets {
          widget_title
          widget_content
          widget_link_label
          widget_link_url
        }
      }
      headings(depth: h2) {
        value
      }
      html
    }
  }
`;