import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function CandidateProfile ({ candidate, setModalCandidateOpen, setSelectedCandidate })  {
  
  const name = candidate?.candidate_name;
  const party = candidate?.candidate_party?.toLowerCase();
  const image = getImage(candidate?.candidate_profile_picture);

  const handleClick = (e) => {
    e.preventDefault(e);
    setModalCandidateOpen(true);
    setSelectedCandidate(candidate);
  }

  return (
    <div className="candidate col-md-4">
      <Link onClick={(e) => handleClick(e)} className="candidate__link" to="/">
        <GatsbyImage
          image={image}
          objectFit="cover"
          alt=""
          placeholder="blurred"
        />
      </Link>
      <div className="candidate__info">
        <p className="candidate__name">{ name }</p>
        <p className={`candidate__party ${party?.replace(/\s+/g, '-').replace("'", '').toLowerCase()}`}>{ party }</p>
        <Link onClick={(e) => handleClick(e)} className="candidate__link" to="/">Find out more</Link>
      </div>
    </div>
  )
}