import * as React from "react"

export default function IconArrow() {
  return (
    <svg 
      width="12" 
      height="16" 
      viewBox="0 0 12 16" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M0 15.5833L11.9167 7.99996L0 0.416626V15.5833Z" 
        fill="#E7513D"
      />
    </svg>
  )
}